<div class="input-group mb-3">
    <input
        id="addressInput"
        #addressInput
        (keyup)="onKeyCanc($event)"
        (ngModelChange)="onKeyCanc($event)"
        [(ngModel)]="this.geocoder.address"
        aria-label="address"
        class="form-control"
        placeholder="{{ 'geocoding.address_label' | translate }}"
        type="search"
    />

    <div class="input-group-append">
        <button
            (click)="searchAddress()"
            style="margin-left: 5px"
            [class]="styleButton"
            mat-button
        >
            {{ "search_label" | translate }}
        </button>
    </div>
</div>
