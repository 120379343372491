import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";

import { DashBoardService } from "core-support/services";
import { Subscription } from "rxjs";
import { Breakpoints } from "@angular/cdk/layout";
import { TranslateModule } from "@ngx-translate/core";
import { NgIf } from "@angular/common";
import { NavigatorComponent } from "../map/navigator/navigator.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { HiddenComponentDisplayDirective } from "core-ui/directive";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-footer",
    styleUrls: ["./footer.component.scss"],
    templateUrl: "./footer.component.html",
    standalone: true,
    imports: [
        HiddenComponentDisplayDirective,
        MatIconModule,
        MatTooltipModule,
        NavigatorComponent,
        NgIf,
        TranslateModule,
    ],
})
export class FooterComponent implements OnInit, OnDestroy {
    legendSrc: string;
    private subscriptionChangeLegend: Subscription;

    constructor(
        private dashboardService: DashBoardService,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.subscriptionChangeLegend =
            this.dashboardService.subjectChangeLegend$.subscribe(
                (res: string) => {
                    this.legendSrc = res;
                    this.cdRef.detectChanges();
                },
            );
    }

    ngOnDestroy(): void {
        this.subscriptionChangeLegend.unsubscribe();
    }

    protected readonly Breakpoints = Breakpoints;
}
