<div>
    <div *ngFor="let product of products | async; let i = index">
        <mat-slide-toggle
            class="mt-2"
            [index]="i"
            [product]="product"
            id="{{ product.key }}"
            radarToogleProduct
        >
            <span class="font-color">{{ product.productLabel }}</span>
            <span
                *radarProductTimeLabel="product.key; index: i"
                class="defaultColor"
            >
                {{ now | amDifference : timeLabel : "minutes" : false }}
                {{ "time.minutes" | translate }} {{ "time.ago" | translate }}
            </span>
        </mat-slide-toggle>
    </div>
</div>
