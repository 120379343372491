import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
const routes: Routes = [
    {
        path: "pages/dashboard",
        loadChildren: () =>
            import("./template/dashboard/dashboard.module").then(
                (mod) => mod.DashboardModule,
            ),
    },

    { path: "", redirectTo: "pages/dashboard", pathMatch: "full" },
    { path: "**", redirectTo: "pages/dashboard" },
];

const config: ExtraOptions = {
    useHash: true,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
