import { TimeDimensionExtensionService } from "../time-dimension-extension.service";
import { take } from "rxjs/operators";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export interface ITimedimensioneState {
    /**
     *
     */
    getRange(): number;

    /**
     *
     */
    reset(): void;

    /**
     *
     */
    newMessageIncomingFromWs(): void;

    /**
     *
     */
    setCurrentTime(): void;
}

export abstract class AbstractTimeDimensionState
    implements ITimedimensioneState
{
    constructor(
        protected _range: number,
        protected timeDimensionService: TimeDimensionExtensionService,
    ) {
        this.timeDimensionService.subjectSetCurrentTime$
            .pipe(take(1))
            .subscribe(() => {
                this.setCurrentTime();
            });
    }

    /**
     *
     */
    getRange(): number {
        return this._range;
    }

    /**
     *
     */
    abstract reset(): void;

    /**
     *
     */
    abstract newMessageIncomingFromWs(): void;

    /**
     *
     */
    abstract setCurrentTime(): void;
}

export class DefaultTimedimensionState extends AbstractTimeDimensionState {
    constructor(
        range: number,
        timeDimensionService: TimeDimensionExtensionService,
    ) {
        super(range, timeDimensionService);
    }

    /**
     *
     */
    override reset() {
        this.timeDimensionService.refreshTimeDimensionAtLastTime();
        this.setCurrentTime();
    }

    /**
     *
     */
    override newMessageIncomingFromWs() {
        if (this.timeDimensionService.autoApdate()) {
            this.timeDimensionService.refreshTimeDimensionAtLastTime();
            this.setCurrentTime();
        }
    }

    /**
     *
     */
    override setCurrentTime() {
        this.timeDimensionService.setCurrentTime(
            new Date(this.timeDimensionService.wsProduct.time).getTime(),
        );
    }
}

export class LoopTimeDimensionState extends AbstractTimeDimensionState {
    constructor(
        range: number,
        timeDimensionService: TimeDimensionExtensionService,
        private refresh: boolean,
    ) {
        super(range, timeDimensionService);
    }

    /**
     *
     */
    override reset(): void {}

    /**
     *
     */
    override newMessageIncomingFromWs(): void {
        if (this.refresh) {
            this.timeDimensionService.refreshTimeDimensionAtLastTime();
        }
    }

    /**
     *
     */
    override setCurrentTime(): void {
        this.timeDimensionService.setCurrentTime(
            this.timeDimensionService.getLowerTimeLimit(),
        );
        // this.timeDimensionService.play(0);
    }
}
