<mat-card class="product-card mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            {{
                "time_selected_label"
                    | translate
                        : {
                              time:
                                  this.currentTime
                                  | date : "dd/MM/y, HH:mm" : "GMT+2"
                          }
            }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-radio-group
            [(ngModel)]="selectedProduct"
            class="product-radio-group"
        >
            <mat-radio-button
                *ngFor="let product of products | async"
                [value]="product"
                class="product-radio-button"
            >
                {{ product.productLabel }}
            </mat-radio-button>
        </mat-radio-group>
    </mat-card-content>
    <mat-card-actions>
        <button
            (click)="downloadProduct()"
            [disabled]="selectedProduct === null"
            class="button-style-download"
            mat-button
        >
            {{ "download_label" | translate }}
        </button>
    </mat-card-actions>

    <div style="display: inline">
        {{ "license_label" | translate }}:
        <a
            aria-label="License"
            href="https://creativecommons.org/licenses/by-sa/4.0/deed.it"
            rel="noopener"
            target="_blank"
            title="CC-BY-SA"
            >CC-BY-SA</a
        >
        <img
            alt="Logo"
            src="https://licensebuttons.net/l/by-sa/4.0/88x31.png"
            style="margin-left: 10px"
        />
    </div>
</mat-card>
