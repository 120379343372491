import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { filter, switchMap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class PwaUpdateService {
    constructor(
        private readonly updates: SwUpdate,
        private translateService: TranslateService,
    ) {}

    /**
     */

    activatePWA(): void {
        this.updates.versionUpdates
            .pipe(
                filter(
                    (evt): evt is VersionReadyEvent =>
                        evt.type === "VERSION_READY",
                ),
            )
            .pipe(switchMap(() => this.translateService.get("new_version")))
            .subscribe((res) => {
                if (confirm(res)) {
                    this.updates
                        .activateUpdate()
                        .then(() => document.location.reload());
                }
            });
    }
}
