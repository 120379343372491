import { BehaviorSubject, Observable, Observer, Subject, interval } from "rxjs";
import { WebSocketSubject, WebSocketSubjectConfig } from "rxjs/webSocket";
import { share, takeWhile } from "rxjs/operators";

import { HeaderService } from "../../header/header.service";
import { Injectable, inject } from "@angular/core";
import { ParseUrlService } from "../../url/parse-url.service";
import { SoundService } from "../../sound/sound.service";
import { TimeDimensionExtensionService } from "../../timedimension/time-dimension-extension.service";
import { UUID } from "angular2-uuid";
import { Environment, WSProductMessage } from "core-support/model";
import { ENVIRONMENT } from "core-support/utility";
import { IWsProductService } from "../strategy/ws-strategy.service";
import { ActionService } from "../../action.service";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class WSRxProductService implements IWsProductService {
    private subjectWebSocket$: WebSocketSubject<any>;
    private connectionStatus$: Observable<boolean>;
    private status$: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private reconnectionObservable: Observable<number>;
    private connectionObserver: Observer<boolean>;
    private wsSubjectConfig: WebSocketSubjectConfig<any>;
    private reconnectInterval: number = 1000;
    private reconnectAttempts: number = 10;
    private selectedProductKey: string;
    private env: Environment = inject(ENVIRONMENT);
    private timeDimensionService: TimeDimensionExtensionService = inject(
        TimeDimensionExtensionService,
    );
    private actionService: ActionService = inject(ActionService);
    private soundService: SoundService = inject(SoundService);
    private urlService: ParseUrlService = inject(ParseUrlService);
    private headerService: HeaderService = inject(HeaderService);

    constructor() {
        this.connectionStatus$ = new Observable((observer) => {
            this.connectionObserver = observer;
        });
        this.actionService.pruductSelectedSubject$.subscribe((res) => {
            this.selectedProductKey = res;
        });
        this.wsSubjectConfig = {
            url: this.env.broker_url,
            closeObserver: {
                next: (e: CloseEvent) => {
                    console.log("@@@@@@@@@@@@@@@@@@@[WS]: connection closed");
                    this.subjectWebSocket$ = null;
                    this.connectionObserver.next(false);
                },
            },
            openObserver: {
                next: (e: Event) => {
                    console.log(
                        `@@@@@@@@@@@@@@@@@@@[WS]: connected to ${this.env.broker_url}`,
                    );
                    this.status$.next(false);
                    this.connectionObserver.next(true);
                },
            },
        };
        this.connectionStatus$.subscribe((isConnected) => {
            if (
                !this.reconnectionObservable &&
                typeof isConnected == "boolean" &&
                !isConnected
            ) {
                this.reconnect();
            }
        });
    }

    connectToWS(): void {
        this.subjectWebSocket$ = new WebSocketSubject(this.wsSubjectConfig);
        this.subjectWebSocket$.subscribe({
            next: (msg) => this.receivingMessage(msg),
            error: (err) => {
                console.error(err);
                this.connectionObserver.next(false);
            },
        });
    }

    /**
     *
     */
    diconnectFromWS(): void {
        this.subjectWebSocket$.complete();
        this.subjectWebSocket$ = undefined;
        this.status$.unsubscribe();
        this.connectionObserver.complete;
    }

    /**
     *
     */
    private reconnect(): void {
        this.reconnectionObservable = interval(this.reconnectInterval).pipe(
            takeWhile((v, index) => {
                console.log(
                    "@@@@@@@@@@@@@@@@@@@[WS]: Try to reconnect, attemps n: " +
                        index,
                );
                return index < this.reconnectAttempts;
            }),
            share(),
        );
        this.reconnectionObservable.subscribe({
            next: () => this.connectToWS(),
            error: () => null,
            complete: () => {
                this.reconnectionObservable = null;
                if (!this.subjectWebSocket$) {
                    this.connectionObserver.complete();
                }
            },
        });
    }

    /**
     *
     * @param message
     */
    private receivingMessage(message: any) {
        try {
            let wsProduct: WSProductMessage = {
                productType: message.productType,
                time: new Date(message.time).getTime(),
                period: message.period,
            };
            if (
                wsProduct.productType === this.selectedProductKey &&
                this.timeDimensionService.getUpperTimeLimit() < wsProduct.time
            ) {
                this.soundService.subjectPlayAudio$.next(null);
                this.timeDimensionService.wsProduct = wsProduct;
                this.timeDimensionService.newWsMessageIncoming(wsProduct);
                this.headerService.notificationLabel$.next(wsProduct);
                this.urlService.updateParam("update", UUID.UUID());
            }
        } catch (e) {
            console.error("Parsing Error:", message);
        }
    }
}
