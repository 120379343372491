import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class DashBoardService {
    subjectChangeLegend$ = new BehaviorSubject(null);
}
