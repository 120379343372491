import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";

import { ActionService } from "core-support/services";
import { Subscription } from "rxjs";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Directive({
    selector: "[radarProductTimeLabel]",
    standalone: true,
})
export class ProductTimeLabelDirective implements OnDestroy, OnInit {
    key: string;
    index: number;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private actionService: ActionService,
        private template: TemplateRef<any>,
        private view: ViewContainerRef,
    ) {
        this.subscriptions.add(
            this.actionService.subjectNotifySlideToggle$.subscribe(
                (res: string) => {
                    this.key === res
                        ? this.view.createEmbeddedView(this.template)
                        : this.view.clear();
                },
            ),
        );
    }

    @Input()
    set radarProductTimeLabel(key: string) {
        this.key = key;
    }

    @Input()
    set radarProductTimeLabelIndex(index: number) {
        this.index = index;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.index === 0
            ? this.view.createEmbeddedView(this.template)
            : this.view.clear();
    }
}
