/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */

/**
 *
 * @param name
 * @param url
 */
export function getParameterByName(
    name: string,
    url = window.location.href,
): string {
    name = name.replace(/[[\]]/g, "\\$&");
    if (url.indexOf(name) > 0) {
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    return "";
}
