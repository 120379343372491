<div class="sidenav-container">
    <mat-toolbar class="toolbar">
        <mat-icon
            (click)="snav.toggle(); calculateTimeForProduct()"
            aria-hidden="false"
            aria-label="Layers"
            class="icon"
            matTooltip="{{ 'tooltip.choose_map' | translate }}"
            >settings
        </mat-icon>
        <radar-header style="width: 100%"></radar-header>
    </mat-toolbar>

    <mat-sidenav-container [hasBackdrop]="false">
        <mat-sidenav
            #snav
            [fixedInViewport]="false"
            [mode]="'over'"
            class="radar-sidenav"
        >
            <radar-sidenav></radar-sidenav>
        </mat-sidenav>

        <mat-sidenav-content>
            <ng-content select="router-outlet"></ng-content>
            <radar-footer></radar-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
