import { Component } from "@angular/core";

import { HeaderService } from "core-support/services";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-timelabel",
    templateUrl: "./productlabel.component.html",
    styleUrls: ["./productlabel.component.scss"],
    standalone: true,
})
export class ProductlabelComponent {
    constructor(public headerService: HeaderService) {}
}
