import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Directive({
    selector: "[radarRespondiveDirective]",
    standalone: true,
})
export class HiddenComponentDisplayDirective implements OnInit {
    @Input() radarRespondiveDirective: string[];

    constructor(
        private template: TemplateRef<any>,
        private view: ViewContainerRef,
        public breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit() {
        this.breakpointObserver
            .observe(this.radarRespondiveDirective)
            .subscribe((state: BreakpointState) => {
                state.matches
                    ? this.view.clear()
                    : this.view.createEmbeddedView(this.template);
            });
    }
}
