import { Injectable } from "@angular/core";
import { RadarDPCLayer } from "core-support/store";
import { Subject } from "rxjs";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class ActionService {
    subjectNotifySlideToggle$: Subject<string> = new Subject<string>();
    afterProductSelect$: Subject<RadarDPCLayer> = new Subject<RadarDPCLayer>();
    pruductSelectedSubject$: Subject<string> = new Subject<string>();
    connectToWsSubject$: Subject<any> = new Subject();
    disconnectToWsSubject$: Subject<any> = new Subject();
}
