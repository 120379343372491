import { Subscription } from "rxjs";
import { HeaderService } from "../../header/header.service";
import { Injectable, inject } from "@angular/core";
import { ParseUrlService } from "../../url/parse-url.service";
import { SoundService } from "../../sound/sound.service";
import { TimeDimensionExtensionService } from "../../timedimension/time-dimension-extension.service";
import { Environment, WSProductMessage } from "core-support/model";
import { ENVIRONMENT } from "core-support/utility";
import { RxStomp, RxStompConfig } from "@stomp/rx-stomp";
import { Message } from "@stomp/stompjs";
import { UUID } from "angular2-uuid";
import { ActionService } from "../../action.service";
import { IWsProductService } from "../strategy/ws-strategy.service";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */

export const rxStompConfig: RxStompConfig = {
    // Which server?

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    reconnectDelay: 500,

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    debug: (msg: string): void => {
        console.log(new Date(), msg);
    },
};

@Injectable({
    providedIn: "root",
})
export class WsStompProductService
    extends RxStomp
    implements IWsProductService
{
    private selectedProductKey: string;
    private timeDimensionService: TimeDimensionExtensionService = inject(
        TimeDimensionExtensionService,
    );
    private soundService: SoundService = inject(SoundService);
    private urlService: ParseUrlService = inject(ParseUrlService);
    private headerService: HeaderService = inject(HeaderService);
    private actionService: ActionService = inject(ActionService);
    private env: Environment = inject(ENVIRONMENT);
    protected topicSubscription: Subscription;

    constructor() {
        super();
        rxStompConfig.brokerURL = this.env.broker_url;
        this.configure(rxStompConfig);
        this.actionService.pruductSelectedSubject$.subscribe((res) => {
            this.selectedProductKey = res;
        });
    }

    /**
     *
     */
    connectToWS(): void {
        this.activate();
        this.topicSubscription = this.watch(this.env.topic_radar).subscribe(
            (message: Message) => {
                const wsProduct: WSProductMessage = JSON.parse(message.body);
                if (
                    wsProduct.productType === this.selectedProductKey &&
                    this.timeDimensionService.getUpperTimeLimit() <
                        wsProduct.time
                ) {
                    this.soundService.subjectPlayAudio$.next(null);
                    this.timeDimensionService.wsProduct = wsProduct;
                    this.timeDimensionService.newWsMessageIncoming(wsProduct);
                    this.headerService.notificationLabel$.next(wsProduct);
                    this.urlService.updateParam("update", UUID.UUID());
                }
            },
        );
    }

    /**
     *
     */
    diconnectFromWS(): void {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
        this.deactivate();
    }
}
