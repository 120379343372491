import { Inject, Injectable } from "@angular/core";
import { GEOJSON_COLLEAGUES } from "core-support/utility";
import { IGeoJsonColleague } from "./colleague/geojson-colleague";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class MediatorService {
    private _layersMap: Map<string, IGeoJsonColleague> = new Map();

    constructor(
        @Inject(GEOJSON_COLLEAGUES)
        private colleagues: ReadonlyArray<IGeoJsonColleague>,
    ) {
        this.registerColleague();
    }

    /**
     *
     * @param date
     * @param layer
     */
    subscribeColleague(date: string, layer: any) {
        if (this._layersMap.has(layer.name)) {
            this._layersMap.get(layer.name).subscribe(date, layer);
        }
    }

    /**
     *
     * @param date
     */
    refreshLayer(date: string) {
        Array.from(this._layersMap.values())
            .filter((l) => l.getSubscription())
            .forEach((l) => {
                l.addFeaturesToLayer(date);
            });
    }

    /**
     *
     * @param key
     */
    unSubcribeColleague(key: string) {
        if (this._layersMap.has(key)) {
            this._layersMap.get(key).unsubscribe();
        }
    }

    /**
     *
     * @private
     */
    private registerColleague(): void {
        this.colleagues.forEach((c) => {
            this._layersMap.set(c.getKey(), c);
        });
    }
}
