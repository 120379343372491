import { Subject, Subscription } from "rxjs";

import { Inject, Injectable } from "@angular/core";
import { RadarHttpService } from "core-support/rest";

import { Environment } from "core-support/model";
import { ENVIRONMENT } from "core-support/utility";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export interface IGeoJsonColleague {
    /**
     *
     */
    unsubscribe(): void;

    /**
     *
     * @param time
     * @param geoJsonLayer
     */
    subscribe(time: string, geoJsonLayer: any): void;

    /**
     *
     * @param time
     */
    addFeaturesToLayer(time: string): void;

    /**
     *
     */
    getSubscription(): Subscription;

    /**
     *
     */
    getKey(): string;
}

@Injectable()
export abstract class GeoJsonColleague implements IGeoJsonColleague {
    private subject = new Subject<any>();
    private subscription: Subscription;
    private geoJsonLayer: any;

    constructor(private httpService: RadarHttpService) {}

    /**
     *
     */
    unsubscribe(): void {
        this.subscription.unsubscribe();
        this.subscription = null;
        this.geoJsonLayer = null;
    }

    /**
     *
     * @param time
     * @param layer
     */
    subscribe(time: string, layer: any): void {
        this.geoJsonLayer = layer.layer;
        this.subscription = this.subject.subscribe((res) => {
            this.geoJsonLayer.clearLayers();
            this.geoJsonLayer.addData(res);
        });
        this.addFeaturesToLayer(time);
    }

    /**
     *
     */
    getSubscription(): Subscription {
        return this.subscription;
    }

    /**
     *
     * @param time
     */
    addFeaturesToLayer(time: string): void {
        this.httpService.getLayerGeoJson(
            this.getLayerUrl(),
            time,
            this.subject,
        );
    }

    /**
     *
     */
    abstract getLayerUrl(): string;

    /**
     *
     */
    abstract getKey(): string;
}

@Injectable()
export class RadarStatusGeoJsonColleague extends GeoJsonColleague {
    constructor(
        httpService: RadarHttpService,
        @Inject(ENVIRONMENT) private env: Environment,
    ) {
        super(httpService);
    }

    /**
     *
     */
    getLayerUrl(): string {
        return `${this.env.radarStatusUrl}`;
    }

    getKey(): string {
        return "RADAR";
    }
}
