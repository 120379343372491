import { createAction, props } from "@ngrx/store";
import { RadarDPCLayer } from "../reducers/layer.reducers";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */

export const loadLayers = createAction(
    "[LAYERS STORE] Load Layers",
    props<{ layers: RadarDPCLayer[] }>(),
);
