import { Observable, Subject, of } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { GeocodingLocation } from "@geosdi/ngx-geocoding/lib/model/geocoding-location.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Location } from "core-support/model";
import { NgxGeocodingService } from "@geosdi/ngx-geocoding";
import { UtilsService } from "core-support/utility";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class GeocodingService {
    openResultSubject$ = new Subject<any>();
    closeResultSubject$ = new Subject<any>();
    address = "";
    result: Observable<any[]>;

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
        private geocodingService: NgxGeocodingService,
    ) {
        this.geocodingService.subjectNoResults.subscribe(() => {
            this.utilsService.showNotification(
                "error",
                "geocoding.no_address_found",
            );
        });
    }

    /**
     *
     *
     * @param {string} address
     * @returns
     * @memberof GeocodingService
     */
    geocode(address: string) {
        this.geocodingService
            .geocode(encodeURIComponent(address))
            .pipe(
                filter((res: GeocodingLocation[]) => res.length > 0),
                tap(() => this.openResultSubject$.next(null)),
            )
            .subscribe((res: GeocodingLocation[]) => {
                this.result = of(res);
            });
    }

    /**
     *
     *
     * @param {Location} location
     * @memberof GeocodingService
     */
    goTo(location: Location) {
        this.closeResultSubject$.next(location);
    }
}
