//@ts-ignore
import * as L from "leaflet";

import { AfterContentInit, Component } from "@angular/core";

import { GeocodingService } from "core-support/services";
import { Location } from "core-support/model";
import { NgxLeafletPingLayerPluginService } from "@geosdi/ngx-leaflet-ping-layer-plugin";
import { Observable } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { AsyncPipe, NgFor } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-geocoding-list",
    templateUrl: "./geocoding-list.component.html",
    styleUrls: ["./geocoding-list.component.scss"],
    standalone: true,
    imports: [
        MatCardModule,
        MatListModule,
        NgFor,
        MatButtonModule,
        AsyncPipe,
        TranslateModule,
    ],
})
export class GeocodingListComponent implements AfterContentInit {
    result: Observable<any[]>;

    constructor(
        private geocodingService: GeocodingService,
        private lLeafletPingLayerPluginService: NgxLeafletPingLayerPluginService,
    ) {}

    ngAfterContentInit(): void {
        this.result = this.geocodingService.result;
    }

    /**
     *
     * @param event
     */
    goTo(event: any) {
        const location = new Location();
        location.address = event.display_name;
        location.lat = event.lat;
        location.lon = event.lon;
        location.latlng = L.latLng(event.lat, event.lon);
        this.geocodingService.goTo(location);
        this.lLeafletPingLayerPluginService.initPingLayer.next([
            event.lon,
            event.lat,
        ]);
    }
}
