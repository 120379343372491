import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";

import { DashBoardService, HeaderService } from "core-support/services";
import { DeviceDetectorService } from "ngx-device-detector";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { Subscription } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import {
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
} from "core-ui/components";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-layout",
    styleUrls: ["./layout.scss"],
    templateUrl: "./layout.html",
    standalone: true,
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        HeaderComponent,
        MatSidenavModule,
        SidenavComponent,
        FooterComponent,
        TranslateModule,
    ],
})
export class TemplateComponent implements OnDestroy, OnInit {
    layout: any = {};
    legendSrc: string;
    deviceService: DeviceDetectorService;
    @ViewChild("snav")
    snav: MatSidenav;
    private alive = true;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private headerService: HeaderService,
        private dashboardService: DashBoardService,
        deviceService: DeviceDetectorService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.deviceService = deviceService;
    }

    /**
     *
     */
    calculateTimeForProduct(): void {
        if (this.snav.opened) {
            this.headerService.openSidebarSubject$.next(null);
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.dashboardService.subjectChangeLegend$.subscribe(
                (res: string) => {
                    this.legendSrc = res;
                    this.cdRef.detectChanges();
                },
            ),
        );
        this.subscriptions.add(
            this.headerService.closingSidebar$.subscribe(() => {
                this.snav.toggle();
            }),
        );
    }

    ngOnDestroy() {
        this.alive = false;
        this.subscriptions.unsubscribe();
    }
}
