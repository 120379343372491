<footer class="footer" style="overflow: hidden !important; margin-left: auto">
    <div class="container" style="max-width: 100%">
        <div class="row">
            <div
                class="col-md-6"
                *radarRespondiveDirective="[Breakpoints.XSmall]"
            >
                <a
                    aria-label="Link"
                    href="https://radar.protezionecivile.it"
                    rel="noopener"
                    target="_blank"
                >
                    <mat-icon
                        aria-hidden="false"
                        aria-label="FullScreen"
                        class="icon"
                        matTooltip="{{ 'tooltip.full_size' | translate }}"
                        >fullscreen
                    </mat-icon>
                </a>
                <radar-map-navigator class="navigator"></radar-map-navigator>
            </div>
            <div class="d-inline-flex col-sm-12 col-md-6 justify-content-end">
                <img *ngIf="legendSrc" [src]="legendSrc" alt="legend" />
            </div>
        </div>
    </div>
</footer>
