import {
    BaseLayerEnum,
    NgxLeafletLayersPluginService,
} from "@geosdi/ngx-leaflet-layers-plugin";
import { Subject } from "rxjs";
import {
    AppState,
    RadarDPCLayer,
    createRadarDPCLayerList,
    loadLayers,
    selectAllLayers,
    selectByGroup,
} from "core-support/store";

import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { Environment } from "core-support/model";
import { ENVIRONMENT } from "core-support/utility";
import { ParseUrlService } from "../../url/parse-url.service";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class LayerService {
    overlayLayersList: RadarDPCLayer[];
    subjectAddLayerProductToMap$ = new Subject<any>();
    baseLayersJSON = {};
    subjectLoadControls$ = new Subject<any>();
    subjectNotifyFirstLoad$ = new Subject();
    private mapActions = new Map();

    constructor(
        private store: Store<AppState>,
        private ngxLeafletLayersPluginService: NgxLeafletLayersPluginService,
        private urlService: ParseUrlService,
        @Inject(ENVIRONMENT) env: Environment,
    ) {
        this.buildMapActions();
        this.store.dispatch(
            loadLayers({
                layers: createRadarDPCLayerList(
                    env,
                    urlService.isItaliaMeteo(),
                ),
            }),
        );
        this.store
            .select(selectAllLayers)
            .pipe(take(1))
            .subscribe((res: RadarDPCLayer[]) => {
                this.overlayLayersList = res;
            });
        this.subjectNotifyFirstLoad$.pipe(take(1)).subscribe(() => {
            this.filterLayerByProduct("VMI", ["VMI", "HRD"]);
        });
    }

    /**
     *
     *
     * @param {string} product
     * @param {string[]} layersToViewInMap
     * @memberof LayerService
     */
    filterLayerByProduct(product: string, layersToViewInMap: string[]) {
        this.store
            .select(this.mapActions.get(product))
            .subscribe((res: any) => {
                this.subjectAddLayerProductToMap$.next({
                    layersForProduct: res.filter(
                        (l: any) => l.key !== product && l.layer,
                    ), // all layers in leaflet controls
                    selectedLayers: res.filter((l: any) =>
                        layersToViewInMap.includes(l.key),
                    ), // layers on
                });
                console.log(res);
                this.overlayLayersList = res;
                this.subjectLoadControls$.next({
                    baseLayers: !this.urlService.isItaliaMeteo()
                        ? this.baseLayersJSON
                        : null,
                    overlays: this.getOverlayLayers(),
                });
            });
    }

    /**
     */
    buildBaseLayers(map: any): any {
        this.ngxLeafletLayersPluginService
            .withBaseLayers([
                BaseLayerEnum.OPEN_STREET_MAP,
                BaseLayerEnum.DARK_BASE_MAP,
            ])
            .withDefaultBaseLayer(BaseLayerEnum.OPEN_STREET_MAP)
            .withMap(map)
            .build();
        Array.from(
            this.ngxLeafletLayersPluginService.baseLayerMap.values(),
        ).map((l) => {
            this.baseLayersJSON = {
                ...this.baseLayersJSON,
                ...{
                    [l.label]: l.layer,
                },
            };
        });
    }

    /**
     * @private
     * @memberof MapService
     */
    private buildMapActions() {
        // this.mapActions.set('IR108', selectByGroup([]));
        // this.mapActions.set('RADAR_STATUS', selectByGroup([]));
        this.mapActions.set(
            "VMI",
            selectByGroup(["VMI", "HRD"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRI",
            selectByGroup(["SRI", "HRD"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRT1",
            selectByGroup(["SRT1"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRT3",
            selectByGroup(["SRT3"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRT6",
            selectByGroup(["SRT6"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRT12",
            selectByGroup(["SRT12"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "SRT24",
            selectByGroup(["SRT24"], this.urlService.isItaliaMeteo()),
        );
        this.mapActions.set(
            "TEMP",
            selectByGroup(["TEMPERATURE"], this.urlService.isItaliaMeteo()),
        );
    }

    /**
     *
     *
     * @returns {*}
     * @memberof LayerService
     */
    private getOverlayLayers(): any {
        let overlayLayersJSON = {};
        this.overlayLayersList.map((l: RadarDPCLayer) => {
            overlayLayersJSON = {
                ...overlayLayersJSON,
                ...{
                    [l.label]: l.layer,
                },
            };
        });
        return overlayLayersJSON;
    }
}
