//@ts-ignore
import * as $jquery from "jquery";

import { Component, OnDestroy, OnInit } from "@angular/core";

import { DeviceDetectorService } from "ngx-device-detector";
import {
    FileService,
    HeaderService,
    LayerService,
    MapService,
    ParseUrlService,
    SoundService,
    TimeDimensionExtensionService,
} from "core-support/services";
import { GeocodingModalComponent } from "../modal/geocoding/geocoding-modal.component";
import { LoopTimeModalComponent } from "../modal/loop-time/loop-time-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { WSProductMessage } from "core-support/model";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { HiddenComponentDisplayDirective } from "core-ui/directive";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { ProductlabelComponent } from "./productlabel/productlabel.component";
import { DownloadProductModalComponent } from "../modal/download/download-product-modal.component";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-header",
    styleUrls: ["./header.component.scss"],
    templateUrl: "./header.component.html",
    standalone: true,
    imports: [
        ProductlabelComponent,
        MatIconModule,
        MatTooltipModule,
        HiddenComponentDisplayDirective,
        MatBadgeModule,
        MatSlideToggleModule,
        TranslateModule,
    ],
})
export class HeaderComponent implements OnInit, OnDestroy {
    notificationsLabel: string = "";
    newMessage = false;
    wsProduct: WSProductMessage;
    deviceService: DeviceDetectorService;
    private subscriptions: Subscription = new Subscription();
    protected readonly Breakpoints = Breakpoints;

    constructor(
        public timeDimensionService: TimeDimensionExtensionService,
        public dialog: MatDialog,
        private mapService: MapService,
        private headerService: HeaderService,
        private soundService: SoundService,
        private fileService: FileService,
        private layerService: LayerService,
        private urlService: ParseUrlService,
        private responsive: BreakpointObserver,
        deviceService: DeviceDetectorService,
    ) {
        this.deviceService = deviceService;
    }

    ngOnInit() {
        this.subscriptions.add(
            this.headerService.notificationLabel$.subscribe(
                (prod: WSProductMessage) => {
                    this.notificationsLabel = "new";
                    this.newMessage = true;
                    this.wsProduct = prod;
                },
            ),
        );
        this.subscriptions.add(
            this.mapService.closingLayerMenuSubject.subscribe(() => {
                $jquery(".leaflet-control-layers").hide();
            }),
        );
    }

    /**
     *
     */
    openLayer() {
        $jquery(".leaflet-control-layers .leaflet-control-layers-list").css(
            "display",
            "inline",
        );
        $jquery(".leaflet-control-layers").css("top", "40px");
        $jquery(".leaflet-control-layers-toggle").hide();
        $jquery(".leaflet-control-layers").toggle();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     *
     */
    showGeocodingModal(): void {
        this.dialog.open(GeocodingModalComponent);
    }

    /**
     *
     */
    refreshTimeDimension(): void {
        this.notificationsLabel = "";
        if (this.newMessage) {
            this.timeDimensionService.reset();
        }
        this.newMessage = false;
    }

    /**
     *
     * @param event
     */
    activeSound(event: any): void {
        this.soundService.subjectLoadAudio$.next(null);
        this.soundService.subjectActivateSound$.next(event.checked);
    }

    /**
     *
     */
    async showDownloadProductModal() {
        this.dialog.open(DownloadProductModalComponent);
    }

    /**
     *
     */
    center(): void {
        this.mapService.centerMap();
    }

    /**
     *
     */
    async openRangeFilter() {
        this.dialog.open(LoopTimeModalComponent);
    }

    /**
     *
     */
    removeRangeFilter(): void {
        this.timeDimensionService.switchToDefaultState();
        this.urlService.removeHashtag();
        this.timeDimensionService.setTransitionTime(1000);
    }
}
