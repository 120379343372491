import { Injectable, WritableSignal, signal } from "@angular/core";
import { Subject } from "rxjs";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class HeaderService {
    productLabelSignal: WritableSignal<string> = signal("");
    notificationLabel$: Subject<any> = new Subject();
    closingSidebar$: Subject<any> = new Subject();
    openSidebarSubject$: Subject<any> = new Subject();
}
