import { Component, HostListener, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TemplateComponent } from "./template/layout/layout";
import { WsStrategyService } from "core-support/services";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-app",
    templateUrl: "./app.component.html",
    standalone: true,
    imports: [TemplateComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
    constructor(private wsStrategyService: WsStrategyService) {}

    ngOnInit() {
        this.wsStrategyService.connect();
    }

    /**
     *
     *
     * @param {*} event
     * @memberof AppComponent
     */
    @HostListener("window:beforeunload", [])
    beforeUnloadHander(event: any) {
        this.wsStrategyService.disconnect();
    }
}
