import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    /**
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((err) => {
                return new Promise<any>((resolve, reject) => {
                    if (
                        err instanceof HttpErrorResponse &&
                        req.responseType === "blob"
                    ) {
                        const reader = new FileReader();
                        reader.onload = (e: Event) => {
                            try {
                                reject(
                                    this.buildHttpResponse(
                                        JSON.parse((<any>e.target).result)
                                            .gpRestExceptionMessage.message,
                                    ),
                                );
                            } catch (e) {
                                reject(err);
                            }
                        };
                        reader.onerror = (e) => {
                            reject(err);
                        };
                        reader.readAsText(err.error);
                    } else if (
                        err instanceof HttpErrorResponse &&
                        req.responseType !== "blob" &&
                        err.status === 400
                    ) {
                        reject(err);
                    } else if (
                        err instanceof HttpErrorResponse &&
                        req.responseType !== "blob" &&
                        err.status !== 400
                    ) {
                        reject(this.buildHttpResponse("Rest Service Error"));
                    }
                });
            }),
        );
    }

    /**
     *
     *
     * @private
     * @param {string} message
     * @returns {HttpErrorResponse}
     * @memberof ResponseInterceptor
     */
    private buildHttpResponse(message: string): HttpErrorResponse {
        return new HttpErrorResponse({
            error: {
                gpRestExceptionMessage: {
                    faultType: "RestServiceFault",
                    message: message,
                },
            },
        });
    }
}
