import { ActionReducerMap } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { reducer } from "./reducers/layer.reducers";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class ReducerService {
    createReducer(): ActionReducerMap<any> {
        return {
            layers: reducer,
        };
    }
}
