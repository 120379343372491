/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export class WideSearchProductRequest {
    productType: string;
    productDate: number;

    constructor(productType: string, productDate: number) {
        this.productType = productType;
        this.productDate = productDate;
    }
}
