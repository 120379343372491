import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { Observable, Subscription, timer } from "rxjs";

import {
    ActionService,
    DashBoardService,
    HeaderService,
    MapService,
    TimeDimensionExtensionService,
    UrlStrategy,
} from "core-support/services";
import {
    MatSlideToggle,
    MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import {
    AppState,
    RadarDPCLayer,
    selectProductLayer,
} from "core-support/store";
import { Store } from "@ngrx/store";
import { WSProductMessage } from "core-support/model";
import { take } from "rxjs/operators";
import { MomentModule } from "ngx-moment";
import { TranslateModule } from "@ngx-translate/core";
import {
    ProductTimeLabelDirective,
    SlideToggleDirective,
} from "core-ui/directive";
import { AsyncPipe, NgFor } from "@angular/common";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-sidenav",
    templateUrl: "./sidenav.component.html",
    standalone: true,
    imports: [
        NgFor,
        MatSlideToggleModule,
        SlideToggleDirective,
        ProductTimeLabelDirective,
        AsyncPipe,
        TranslateModule,
        MomentModule,
    ],
})
export class SidenavComponent implements OnInit, OnDestroy {
    products: Observable<RadarDPCLayer[]>;
    @ViewChildren(MatSlideToggle)
    productsSlideToggle!: QueryList<MatSlideToggle>;

    timeLabel: Date;
    now: Date;
    private timer = timer((60 - new Date().getSeconds()) * 1000, 60000);
    private productSelected: RadarDPCLayer;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private headerService: HeaderService,
        private store: Store<AppState>,
        private timeDimensionService: TimeDimensionExtensionService,
        private dashboardService: DashBoardService,
        private cdRef: ChangeDetectorRef,
        private mapService: MapService,
        private actionService: ActionService,
        private urlStrategy: UrlStrategy,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.headerService.notificationLabel$.subscribe(
                (prod: WSProductMessage) => {
                    this.timeLabel = new Date(prod.time);
                },
            ),
        );
        this.subscriptions.add(
            this.mapService.subjectNotifyTimeDImension$
                .pipe(take(1))
                .subscribe(() => {
                    this.getProductStore();
                }),
        );
        this.subscriptions.add(
            this.timer.subscribe((val) => {
                this.now = new Date();
                this.cdRef.detectChanges();
            }),
        );
        this.subscriptions.add(
            this.headerService.openSidebarSubject$.subscribe(() => {
                this.timeLabel = new Date(
                    this.timeDimensionService.getUpperTimeLimit(),
                );
                this.cdRef.detectChanges();
            }),
        );
        this.subscriptions.add(
            this.actionService.afterProductSelect$.subscribe(
                (res: RadarDPCLayer) => {
                    this.afterProductSelect(res);
                    this.headerService.closingSidebar$.next(null);
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     *
     */
    private getProductStore() {
        this.products = this.store.select(selectProductLayer).pipe(take(1));
        this.products.subscribe((res: RadarDPCLayer[]) => {
            this.afterProductSelect(res[0]);
        });
    }

    /**
     *
     * @param product
     */
    private afterProductSelect(product: RadarDPCLayer) {
        this.dashboardService.subjectChangeLegend$.next(product.imageUrl);
        this.headerService.productLabelSignal.set(product.headerLabel);
        this.urlStrategy.applyOperation(product);
        this.productSelected = product;
    }
}
