import { ActionReducer, MetaReducer } from "@ngrx/store";

export * from "./lib/actions/layer.action";
export * from "./lib/reducers/layer.reducers";
export * from "./lib/reducer.service";
export * from "./lib/selectors/layer.selectors";

export interface AppState {}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        console.log("state", state);
        console.log("action", action);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [logger];
