//@ts-ignore
import { LatLng, LatLngBounds } from "leaflet";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export class Location {
    latlng: LatLng;
    address: string;
    viewBounds: LatLngBounds;
    lat: number;
    lon: number;
}
