(L.TimeDimension.Layer.Basic = L.TimeDimension.Layer.WMS.extend({
    onAdd: function (map) {
        // this.refreshed = false;
        this._map = map;
        if (!this._timeDimension && map.timeDimension) {
            this._timeDimension = map.timeDimension;
        }
        this._timeDimension.on("timeloading", this._onNewTimeLoading, this);
        this._timeDimension.on("timeload", this._update, this);
        this._timeDimension.registerSyncedLayer(this);
        this._update();
        if (this._availableTimes.length == 0) {
            null;
        } else {
            this._updateTimeDimensionAvailableTimes();
        }
    },
})),
    (L.timeDimension.layer.wms.basic = function (i, e) {
        return new L.TimeDimension.Layer.Basic(i, e);
    });
