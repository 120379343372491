<div class="row">
    <div class="align-items-center d-inline-flex col-6 col-sm-3">
        <radar-timelabel></radar-timelabel>
        <mat-icon
            (click)="openLayer()"
            aria-hidden="false"
            aria-label="Layers"
            class="icon"
            matTooltip="{{ 'tooltip.add_layer' | translate }}"
            >layers
        </mat-icon>
        <img
            (click)="center()"
            alt="italy"
            class="image-margin"
            matTooltip="{{ 'tooltip.italy' | translate }}"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADC0lEQVRYha2YXWhOcRzHP2YzM7LVapootTLMyxVJ1ETsYlPcKBdawjKXSl5CDRe4I6GWiym5cEFedkEkpVabJkbMS/IyiY1RhvnqPH6PTmfPeZ7/c8751r9z/m+/vuf3+/7+L2cMgCQcUQxUAOOA1cAWoAc4Bjx0NZITHqEsZbz1lUs6IWlEozEsqUXSHEm1kkpz2AwtkJnQUknF9n5KUrOkjgxEwrAqKqFC8954oAYoAdYAO4E24BOwNYLTi+KG7LikzyHhyBe9kubG9ZAn1PIY3zQEXABe2bMvqqE0of4YZDxcBzbHtJFCgT03xrTzOgEuKaQJTYpppyQBLimkCfXGtPMzAS4ppAltMEH6sQeotbISeJPFznpgWVKk0ilXKWm/pDOSpkoqCKTkEkkfsywBg5J6JC2MmvKZVmqPRFGWCZ0Oa9KApPlRCRUEnPUH+JXFmWcdHF4GbALGRolWvrs9tqvPcRi3wE4CeSHoIRdsAx47jGuJYPsfIsR6hqRrDnranq/tKCHz47ylfDa0Ai/Nq1+AEeBp0h7yl72Svjl4q1/SE0nH8smyKDhox9krOeZWAjOdzkpZGNdJapNU5eCpyZIaJV3O4alXki5J2umyMKZLg6Q7kj6YkXZJZY4hLLcj8AGHMHYGiYUROhmYOCSpJoK+Fkt670DM01Z1Ng09D9QnAqUR9HUPqAPeZejz2nYDN0xbXcCsMA/VB77gvqRpMTKx2mz40WR93jXrkGXq4TBCjYHJrTGXhrS2Lvps7pBU4uuf593nwkI2PVD/ESFcQQwA+4BBaz8KNPjGPAC+hxF6CwzbezdwNQFCHh4B7b56VXBAYbDB0AE02a5+BPiaECE/iW4T/WgkoA/Xssv080LSlExz4m6ufiwHJgA3gd92QJPpsR5Y4dPMIqAzk5GwkOWLtcA5I9BlO3qB1Svtv4F3s2kGnoWRSZLQgGWidx1fmqH/PbDEl2GhSGK393DLwuBdhW6HEM5J5j8SFm6F/bi661sE+1znJynqILzbx2zgtJ0Q1+WcAfwF28WlvFqkGEYAAAAASUVORK5CYII="
            style="cursor: pointer"
        />
        <mat-icon
            (click)="showDownloadProductModal()"
            aria-hidden="false"
            aria-label="Download"
            class="icon"
            matTooltip="{{ 'download_label' | translate }}"
            >file_download
        </mat-icon>

        <mat-icon
            (click)="openRangeFilter()"
            aria-hidden="false"
            aria-label="Range Filter"
            class="icon"
            matTooltip="{{ 'date.range' | translate }}"
            >update
        </mat-icon>

        <mat-icon
            (click)="removeRangeFilter()"
            aria-hidden="false"
            aria-label="Reset Filter"
            class="icon"
            matTooltip="{{ 'date.reset' | translate }}"
            >clear
        </mat-icon>
    </div>

    <ng-container *radarRespondiveDirective="[Breakpoints.XSmall]">
        <div class="col-7" id="timedimensioncontainer"></div>
    </ng-container>

    <div
        class="align-items-center d-inline-flex col-6 col-sm-2 justify-content-end"
    >
        <mat-icon
            (click)="showGeocodingModal()"
            aria-hidden="false"
            aria-label="Geocoding"
            class="icon d-sm-inline d-lg-none"
            matTooltip="{{ 'geocoding.search_address' | translate }}"
            >place
        </mat-icon>

        <mat-icon
            (click)="refreshTimeDimension()"
            class="icon"
            matBadge="{{ notificationsLabel }}"
            matBadgeColor="accent"
            matTooltip="{{ 'notification' | translate }}"
            >notifications
        </mat-icon>

        <mat-slide-toggle
            (change)="activeSound($event)"
            [checked]="false"
            aria-label="active-notification"
            class="image-margin"
            matTooltip="{{ 'tooltip.update_notification' | translate }}"
            style="margin-right: 3px"
        >
        </mat-slide-toggle>
    </div>
</div>
