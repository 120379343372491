import * as fromLayer from "../reducers/layer.reducers";
import { RadarDPCLayer, State } from "../reducers/layer.reducers";
/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const layersState =
    createFeatureSelector<State<RadarDPCLayer>>("layers");
export const selectAllLayers = createSelector(layersState, fromLayer.selectAll);

export const selectProductLayer = createSelector(
    selectAllLayers,
    (layers: RadarDPCLayer[]) => {
        return layers.filter((l) => l.product);
    },
);

export const selectByLabel = (label: string) =>
    createSelector(selectAllLayers, (layers: RadarDPCLayer[]) => {
        return layers.filter((l) => l.label === label);
    });

export const selectByGroup = (groups: string[], isItaliaMeteo: boolean) =>
    createSelector(selectAllLayers, (layers: RadarDPCLayer[]) => {
        return layers.filter((l) =>
            !isItaliaMeteo
                ? groups.includes(l.group) || l.group === "ALWAYS_VISIBLE"
                : groups[0] === l.key || l.key === "RADAR_STATUS",
        );
    });
