import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDateFormats,
    NgxMatDatetimePickerModule,
} from "@angular-material-components/datetime-picker";

import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular-material-components/moment-adapter";
import {
    ParseUrlService,
    TimeDimensionExtensionService,
} from "core-support/services";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: "",
    },
    display: {
        dateInput: "DD-MM-YYYY HH:mm:ss",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

let AppDateAdapter;

@Component({
    selector: "radar-loop-time-modal",
    templateUrl: "./loop-time-modal.component.html",
    styleUrls: ["./loop-time-modal.component.scss"],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
        {
            provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        NgxMatDatetimePickerModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        TranslateModule,
    ],
})
export class LoopTimeModalComponent implements OnInit {
    @ViewChild("date1", { static: false })
    date1: any;
    @ViewChild("date2", { static: false })
    date2: any;
    minuteStep = 0;
    periodInMs = 0;
    productName: string;
    refresh: boolean = true;
    max: Date;
    min: Date;
    maxDateFrom: Date;
    minDateTo: Date;
    dateControlFrom: FormControl;
    dateControlTo: FormControl;

    constructor(
        private timeDimensionService: TimeDimensionExtensionService,
        public dialog: MatDialog,
        private urlService: ParseUrlService,
    ) {
        this.productName = this.timeDimensionService.wsProduct.productType;
    }

    ngOnInit() {
        this.dateControlFrom = new FormControl(
            new Date(this.timeDimensionService.getLowerTimeLimit()),
        );
        this.dateControlTo = new FormControl(
            new Date(this.timeDimensionService.getUpperTimeLimit()),
        );
        this.max = new Date(this.timeDimensionService.getUpperTimeLimit());
        this.min = new Date(this.timeDimensionService.getLowerTimeLimit());
        this.parsePeriod();
        this.maxDateFrom = new Date(
            new Date(this.timeDimensionService.getUpperTimeLimit()).getTime() -
                this.periodInMs,
        );
    }

    /**
     *
     */
    filter() {
        this.timeDimensionService.switchToLoopState(
            new Date(this.dateControlFrom.value),
            new Date(this.dateControlTo.value),
            this.refresh,
        );
        this.dialog.closeAll();
        this.urlService.removeHashtag();
    }

    /**
     *
     */
    isRangeValid(): boolean {
        return (
            this.dateControlFrom.value !== null &&
            this.dateControlTo !== null &&
            new Date(
                new Date(this.dateControlFrom.value).getTime() +
                    this.periodInMs,
            ).getTime() <= new Date(this.dateControlTo.value).getTime()
        );
    }

    /**
     *
     */
    onChangeDateFrom($event: any) {
        this.minDateTo = new Date(
            new Date(this.dateControlFrom.value?.toLocaleString()).getTime() +
                this.periodInMs,
        );
    }

    /**
     *
     */
    onChangeDateTo($event: any) {
        this.maxDateFrom = new Date(
            new Date(this.dateControlTo.value?.toLocaleString()).getTime() -
                this.periodInMs,
        );
    }

    /**
     *
     */
    private parsePeriod() {
        let p = this.timeDimensionService.wsProduct.period.replace("PT", "");
        if (p.includes("H")) {
            this.periodInMs += Number(p.substring(0, p.indexOf("H"))) * 3600000;
            p = p.substring(0, p.indexOf("H") + 1);
        }
        if (p.includes("M")) {
            const minutes = Number(p.substring(0, p.indexOf("M")));
            this.periodInMs += minutes * 60000;
            this.minuteStep = minutes;
        }
    }
}
