import { Observable, Subscription, fromEvent } from "rxjs";

import { Injectable } from "@angular/core";
import { UtilsService } from "core-support/utility";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class CheckConnectionsService {
    // public onlineEvent: Observable<Event>;
    public offlineEvent: Observable<Event>;
    private subscriptions: Subscription = new Subscription();

    constructor(private utilsService: UtilsService) {
        // this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, "offline");
        this.subscriptions.add(
            this.offlineEvent.subscribe((res) => {
                this.utilsService.showNotification("error", "offline_alert");
            }),
        );
        // this.subscriptions.add(this.onlineEvent.subscribe(e => {
        //
        // }));
    }

    unsubscribeCheckConnectionsService(): void {
        this.subscriptions.unsubscribe();
    }
}
