require("./basic-timedimension");

L.TimeDimension.Layer.AmvLtg = L.TimeDimension.Layer.Basic.extend({
    onAdd: function (map) {
        L.TimeDimension.Layer.Basic.prototype.onAdd.call(this, map);
        this.firsLoad = false;
    },

    initialize: function (layer, options, interval, type, url) {
        L.TimeDimension.Layer.WMS.prototype.initialize.call(
            this,
            layer,
            options,
        );
        this._interval = interval;
        this.url = url;
        this.type = type;
        this.timeToLoad;
    },

    refreshLayer: function () {
        if (!this._map) return;
        // if (!this.timeToLoad)
        //     this.checkTime(time);
        var layer = this._getLayerForTime(this.timeToLoad);
        if (this._currentLayer == null) {
            this._currentLayer = layer;
        }
        if (!this._map.hasLayer(layer)) {
            this._map.addLayer(layer);
        } else {
            this._showLayer(layer, this.timeToLoad);
        }
    },

    _update: function () {
        var length = this._timeDimension.getAvailableTimes().length;
        const upperLimitDate =
            this._timeDimension.getAvailableTimes()[length - 1];
        if (!this.firsLoad) {
            //GET LAST DATE
            const minutes =
                new Date(upperLimitDate).getMinutes() % this._interval;
            this.timeToLoad =
                minutes > 0 ? upperLimitDate - minutes * 60000 : upperLimitDate;
            this.firsLoad = true;
            this.refreshLayer();
        }
        if (!this.refreshedLayer) {
            this.refreshLayer();
        }
        this.refreshedLayer = false;
    },

    _onNewTimeLoading: function (ev) {
        this.checkTime(ev.time);
        var layer = this._getLayerForTime(this.timeToLoad);
        if (!this._map.hasLayer(layer)) {
            this._map.addLayer(layer);
        }
    },

    isReady: function (time) {
        this.checkTime(time);
        var layer = this._getLayerForTime(this.timeToLoad);
        if (this.options.bounds && this._map)
            if (!this._map.getBounds().contains(this.options.bounds))
                return true;
        return layer.isLoaded();
    },

    checkTime: function (currentTime) {
        const xmlhttp = new XMLHttpRequest();
        var length = this._timeDimension.getAvailableTimes().length;
        const upperLimitDate =
            this._timeDimension.getAvailableTimes()[length - 1];
        if (currentTime < upperLimitDate) {
            const minutes = new Date(currentTime).getMinutes() % this._interval;
            this.timeToLoad =
                minutes > 0 ? currentTime - minutes * 60000 : currentTime;
        } else {
            var self = this;
            xmlhttp.open(
                "GET",
                this.url +
                    "/product/existsProduct?type=" +
                    this.type +
                    "&time=" +
                    currentTime,
            );
            xmlhttp.send();
            xmlhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var res = JSON.parse(this.responseText);
                    if (res) {
                        self.timeToLoad = currentTime;
                    } else {
                        const minutes =
                            new Date(currentTime).getMinutes() % self._interval;
                        self.timeToLoad =
                            minutes > 0
                                ? currentTime - minutes * 60000
                                : currentTime;
                    }
                    self.refreshLayer();
                    self.refreshedLayer = true;
                }
            };
        }
    },
});

L.timeDimension.layer.wms.amv = function (layer, options, interval, type, url) {
    return new L.TimeDimension.Layer.AmvLtg(
        layer,
        options,
        interval,
        type,
        url,
    );
};
