import {
    DefaultTimedimensionState,
    ITimedimensioneState,
    LoopTimeDimensionState,
} from "./state/itimedimensione-state";
import {
    INgxLeafletTimedimensionPluginService,
    NgxLeafletTimedimensionPluginService,
} from "@geosdi/ngx-leaflet-timedimension-plugin";
import { Inject, Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ENVIRONMENT, HASHTAG_CENTRO_METEO_DPC } from "core-support/utility";
import { LayerService } from "../map/layer/layer.service";
import { MediatorService } from "../map/mediator/mediator.service";
import { Subject } from "rxjs";
import { Environment, WSProductMessage } from "core-support/model";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class TimeDimensionExtensionService
    extends NgxLeafletTimedimensionPluginService
    implements INgxLeafletTimedimensionPluginService
{
    subjectSetCurrentTime$ = new Subject();
    private _state: ITimedimensioneState;
    private url;

    constructor(
        breakpointObserver: BreakpointObserver,
        private mediatorService: MediatorService,
        private layerService: LayerService,
        @Inject(HASHTAG_CENTRO_METEO_DPC) private hashtagCentroMeteoDPC: string,
        @Inject(ENVIRONMENT) private env: Environment,
    ) {
        super(breakpointObserver);
        this.url = new URL(window.location.href);
    }

    private _wsProduct: WSProductMessage;

    /**
     *
     */
    get wsProduct(): WSProductMessage {
        return this._wsProduct;
    }

    /**
     *
     * @param value
     */
    set wsProduct(value: WSProductMessage) {
        this._wsProduct = value;
        this.period = value.period;
    }

    /**
     *
     */
    override refreshTimeDimensionAtLastTime(): void {
        const endTime = new Date(this._wsProduct.time);
        const startTime = new Date(
            this._wsProduct.time - this._state.getRange(),
        );
        this.refreshAvailableTimes(startTime, endTime);
    }

    /**
     *
     */
    public switchToDefaultState() {
        this.setPlayerToLoop(false);
        const range = this.env.range;
        this.changeState(
            new DefaultTimedimensionState(range, this),
            new Date(this.wsProduct.time - range),
            new Date(this.wsProduct.time),
        );
    }

    /**
     *
     * @param startTime
     * @param endTime
     * @param refresh
     */
    public switchToLoopState(startTime: Date, endTime: Date, refresh: boolean) {
        this.changeState(
            new LoopTimeDimensionState(
                endTime.getTime() - startTime.getTime(),
                this,
                refresh,
            ),
            startTime,
            endTime,
        );
    }

    /**
     *
     * @param wsProduct
     */
    newWsMessageIncoming(wsProduct: WSProductMessage) {
        this._wsProduct = wsProduct;
        this._state.newMessageIncomingFromWs();
    }

    /**
     *
     */
    reset() {
        this._state.reset();
        this.setPlayerToLoop(true);
    }

    /**
     */
    override applyTimeDimensionPlugin(): void {
        super.applyTimeDimensionPlugin();
        this.setPlayerToLoop(
            this.url.href.includes(this.hashtagCentroMeteoDPC),
        );
        if (this.url.href.includes(this.hashtagCentroMeteoDPC)) {
            this.play(0);
        }
    }

    /**
     */
    protected override onAvailableTimeChangedChaged(): void {
        this.subjectSetCurrentTime$.next(null);
    }

    /**
     */
    protected override onLoadTimeChaged(): void {
        this.layerService.subjectNotifyFirstLoad$.next(null);
        this.mediatorService.refreshLayer(
            new Date(this.getCurrentTime()).toISOString(),
        );
    }

    /**
     *
     * @param period
     * @private
     */
    private changePeriodForPT5M(period: string): string {
        return period === "PT5M" &&
            this.url.href.includes(this.hashtagCentroMeteoDPC)
            ? "PT1H"
            : period;
    }

    /**
     *
     * @param state
     * @param startTime
     * @param endTime
     */
    private changeState(
        state: ITimedimensioneState,
        startTime: Date,
        endTime: Date,
    ) {
        this._state = state;
        super.refreshAvailableTimes(startTime, endTime);
    }
}
