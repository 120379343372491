/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "core-support/utility";
import { Environment } from "core-support/model";
import { WSRxProductService } from "../rx/ws-rx-product.service";
import { WsStompProductService } from "../stomp/ws-stomp-product.service";

export interface IWsProductService {
    /**
     *
     */
    connectToWS(): void;

    /**
     *
     */
    diconnectFromWS(): void;
}

@Injectable({
    providedIn: "root",
})
export class WsStrategyService {
    private wsService: IWsProductService;

    constructor(@Inject(ENVIRONMENT) env: Environment) {
        this.wsService =
            env.ws_strategy === "stomp"
                ? new WsStompProductService()
                : new WSRxProductService();
    }

    /**
     *
     */
    connect(): void {
        this.wsService.connectToWS();
    }

    /**
     *
     */
    disconnect(): void {
        this.wsService.diconnectFromWS();
    }
}
