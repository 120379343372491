import {
    Directive,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import {
    MatSlideToggle,
    MatSlideToggleChange,
} from "@angular/material/slide-toggle";

import { ActionService, LayerService } from "core-support/services";
import { RadarDPCLayer } from "core-support/store";
import { Subscription } from "rxjs";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Directive({
    selector: "[radarToogleProduct]",
    standalone: true,
})
export class SlideToggleDirective implements OnDestroy, OnInit {
    @Input() index: number;
    @Input() product: RadarDPCLayer;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private slideToggle: MatSlideToggle,
        private actionService: ActionService,
        private layerService: LayerService,
    ) {
        this.subscriptions.add(
            this.actionService.subjectNotifySlideToggle$.subscribe(
                (res: string) => {
                    this.slideToggle.checked = this.product.key === res;
                },
            ),
        );
    }

    @HostListener("change", ["$event"]) onChangeProduct(
        event: MatSlideToggleChange,
    ) {
        this.layerService.filterLayerByProduct(this.product.key, [
            this.product.key,
        ]);
        this.actionService.afterProductSelect$.next(this.product);
        this.actionService.subjectNotifySlideToggle$.next(this.product.key);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.slideToggle.checked = this.index === 0;
    }
}
