import { filter, map, take, withLatestFrom } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class SoundService {
    subjectActivateSound$: Subject<boolean> = new Subject<boolean>();
    subjectLoadAudio$ = new Subject();
    subjectPlayAudio$ = new Subject();
    private alertAudio: any;

    constructor() {
        this.subjectLoadAudio$.pipe(take(1)).subscribe(() => {
            this.alertAudio = new Audio();
            this.alertAudio.src = "./assets/alert/alarm.mp3";
            this.alertAudio.load();
        });
        this.subjectPlayAudio$
            .pipe(
                withLatestFrom(this.subjectActivateSound$),
                map(([first, second]) => {
                    return second;
                }),
                filter((res: boolean) => res),
            )
            .subscribe(() => {
                this.alertAudio.play();
            });
    }
}
