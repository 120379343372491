<mat-card class="mat-elevation-z0 geocoding_list" style="padding: 20px">
    <mat-card-header>
        <mat-card-title>
            {{ "geocoding.result_label" | translate }}
        </mat-card-title>
    </mat-card-header>
    <div class="container">
        <mat-list>
            <mat-list-item *ngFor="let location of result | async">
                <div class="row">
                    <div class="col-8">
                        <h4 class="mat-h4 text-truncate">
                            {{ location.display_name }}
                        </h4>
                    </div>
                    <div class="col-4">
                        <button
                            (click)="goTo(location)"
                            class="button-style-download"
                            mat-button
                        >
                            {{ "geocoding.go_label" | translate }}
                        </button>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-card>
