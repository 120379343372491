import { InjectionToken } from "@angular/core";
import { Environment, MapCenterConfig } from "core-support/model";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export const MAP_CENTER_CONFIG = new InjectionToken<MapCenterConfig>(
    "mapCenter.config",
);
export const HASHTAG_CENTRO_METEO_DPC = new InjectionToken<MapCenterConfig>(
    "hashtag_centro_meteo_dpc",
);
export const GEOJSON_COLLEAGUES = new InjectionToken<any>("geojson");

export const MAP_CENTER: MapCenterConfig = {
    lat: 42.34,
    lng: 12.6,
};

export const ENVIRONMENT: InjectionToken<Environment> =
    new InjectionToken<Environment>("environment");
