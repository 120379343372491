<mat-card class="product-card mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            {{ "table.products.product_type_label" | translate }}:
            {{ this.productName }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field style="margin-right: 5px">
            <input
                (ngModelChange)="onChangeDateFrom($event)"
                [formControl]="dateControlFrom"
                [max]="maxDateFrom"
                [min]="min"
                [ngxMatDatetimePicker]="pickerFrom"
                matInput
                placeholder="{{ 'date.from' | translate }}"
                readonly
                required="true"
            />
            <mat-datepicker-toggle
                [for]="pickerFrom"
                matSuffix
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
                #pickerFrom
                [enableMeridian]="false"
                [showSeconds]="false"
                [showSpinners]="true"
                [stepHour]="1"
                [stepMinute]="minuteStep"
                [touchUi]="false"
            >
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field style="margin-right: 5px">
            <input
                (ngModelChange)="onChangeDateTo($event)"
                [formControl]="dateControlTo"
                [max]="max"
                [min]="minDateTo"
                [ngxMatDatetimePicker]="pickerTo"
                matInput
                placeholder="{{ 'date.to' | translate }}"
                readonly
                required="true"
            />
            <mat-datepicker-toggle
                [for]="pickerTo"
                matSuffix
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
                #pickerTo
                [enableMeridian]="false"
                [showSeconds]="false"
                [showSpinners]="true"
                [stepHour]="1"
                [stepMinute]="minuteStep"
                [touchUi]="false"
            >
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-checkbox [(ngModel)]="refresh" [checked]="refresh">{{
            "date.refresh" | translate
        }}</mat-checkbox>

        <!--      <span class="example-error" *ngIf="date2.invalid && date2.errors.owlDateTimeRange">-->
        <!--                Date Time Range Invalid.-->
        <!--            </span>-->
    </mat-card-content>
    <mat-card-actions>
        <button
            (click)="filter()"
            [disabled]="!isRangeValid()"
            class="button-style-download"
            mat-button
        >
            {{ "date.filter" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
