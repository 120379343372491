import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class UtilsService {
    constructor(
        private translate: TranslateService,
        private snackBar: MatSnackBar,
    ) {}

    /**
     *
     */
    getCurrentLang(): string {
        return this.translate.currentLang;
    }

    /**
     *
     * @param typeError
     * @param i18nKey
     * @param interpolateParams
     * @param header
     */
    showNotification(
        typeError: string,
        i18nKey: string,
        interpolateParams?: Object,
        header = "",
    ) {
        this.translate
            .get(i18nKey, interpolateParams)
            .subscribe((res: string) => {
                this.snackBar.open(res, "", {
                    duration: 4000,
                    announcementMessage: header,
                    panelClass: [typeError],
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
            });
    }
}
