import { Component } from "@angular/core";
import { NavigatorComponent } from "../../map/navigator/navigator.component";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-geocoding-modal",
    templateUrl: "./geocoding-modal.component.html",
    standalone: true,
    imports: [NavigatorComponent],
})
export class GeocodingModalComponent {
    constructor() {}
}
