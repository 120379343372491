require("./basic-timedimension");

L.TimeDimension.Layer.Srt = L.TimeDimension.Layer.Basic.extend({
    onAdd: function (map) {
        L.TimeDimension.Layer.Basic.prototype.onAdd.call(this, map);
        this.firsLoad = false;
    },

    initialize: function (layer, options, type, url) {
        L.TimeDimension.Layer.WMS.prototype.initialize.call(
            this,
            layer,
            options,
        );
        this.type = type;
        this.timeToLoad;
        this.url = url;
        this.lastDate;
        this.xmlhttp = new XMLHttpRequest();
    },

    refreshLayer: function () {
        if (!this._map) return;
        this.checkTime(this._timeDimension.getCurrentTime());
        var layer = this._getLayerForTime(this.timeToLoad);
        if (this._currentLayer == null) {
            this._currentLayer = layer;
        }
        if (!this._map.hasLayer(layer)) {
            this._map.addLayer(layer);
        } else {
            this._showLayer(layer, this.timeToLoad);
        }
    },

    _update: function () {
        var self = this;
        if (!this.firsLoad) {
            //GET LAST DATE
            this.xmlhttp.open(
                "GET",
                this.url + "/product/findLastProductByType?type=" + this.type,
            );
            this.xmlhttp.send();
            this.xmlhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var res = JSON.parse(this.responseText);
                    self.timeToLoad = res.lastProducts[0].time;
                    self.lastDate = res.lastProducts[0].time;
                    self.refreshLayer();
                    self.firsLoad = true;
                    //Update last date every hour if user dont't refresh page
                    setInterval(function doSomething() {
                        self.lastDate = self.lastDate + 3600000;
                    }, 3600000);
                }
            };
        } else {
            this.refreshLayer();
        }
    },

    _onNewTimeLoading: function (ev) {
        this.checkTime(ev.time);
        var layer = this._getLayerForTime(this.timeToLoad);
        if (!this._map.hasLayer(layer)) {
            this._map.addLayer(layer);
        }
    },

    isReady: function (time) {
        this.checkTime(time);
        var layer = this._getLayerForTime(this.timeToLoad);
        if (this.options.bounds && this._map)
            if (!this._map.getBounds().contains(this.options.bounds))
                return true;
        return layer.isLoaded();
    },

    checkTime: function (time) {
        if (this.lastDate < time) {
            this.timeToLoad = this.lastDate;
        } else {
            const minutes = new Date(time).getMinutes();
            this.timeToLoad = minutes > 0 ? time - minutes * 60000 : time;
        }
    },
});

L.timeDimension.layer.wms.srt = function (layer, options, type, url) {
    return new L.TimeDimension.Layer.Srt(layer, options, type, url);
};
