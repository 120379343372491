import { ActivatedRoute, Router } from "@angular/router";
import { Inject, Injectable, Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import { HASHTAG_CENTRO_METEO_DPC } from "core-support/utility";
import { Location } from "@angular/common";
import { getParameterByName } from "./radar-dpc-utils";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class ParseUrlService {
    subjectNotifyStrategy$: BehaviorSubject<boolean>;
    zoom: number;
    private url: any;
    private router: Router;
    private route: ActivatedRoute;

    constructor(
        private injector: Injector,
        @Inject(HASHTAG_CENTRO_METEO_DPC) private hashtagCentroMeteoDPC: string,
        private locationS: Location,
        private deviceService: DeviceDetectorService,
    ) {
        this.router = this.injector.get(Router);
        this.route = this.injector.get(ActivatedRoute);
        this.zoom =
            getParameterByName("zoom") &&
            getParameterByName("zoom") !== null &&
            !isNaN(Number(getParameterByName("zoom")))
                ? +getParameterByName("zoom")
                : this.deviceService.isMobile()
                  ? 5
                  : 6;
        /*        this.updateParam("update", UUID.UUID());
setTimeout(() => {
this.updateParam("zoom", this.zoom);
}, 1000);*/
    }

    /**
     *
     * @param param
     * @param zoom
     */
    public updateParam(param: string, value: any): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [param]: value },
            /*            fragment: this.locationS.path().includes(this.hashtagCentroMeteoDPC)
? this.hashtagCentroMeteoDPC
: null,*/
            queryParamsHandling: "merge",
            preserveFragment: false,
        });
    }

    /**
     *
     */
    public parseUrl(): Promise<any> {
        return new Promise<any>((resolve) => {
            this.url = new URL(window.location.href);
            this.subjectNotifyStrategy$ = new BehaviorSubject<boolean>(
                this.url.href.includes(this.hashtagCentroMeteoDPC),
            );
        });
    }

    /**
     *
     * @param hashtag
     */
    public removeHashtag() {
        this.locationS.replaceState(
            this.locationS.path().replace("#" + this.hashtagCentroMeteoDPC, ""),
        );
    }

    /**
     *
     */
    public isItaliaMeteo(): boolean {
        return getParameterByName("tema").toLowerCase() === "italiameteo";
    }
}
