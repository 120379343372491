import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(private httpClient: HttpClient) {}

    /**
     *
     * @param url
     */
    readFile(url: string): Observable<any> {
        return this.httpClient
            .get<any>(`${url}`, {
                headers: new HttpHeaders({
                    "Cache-Control":
                        "no-cache, no-store, must-revalidate, post- check=0, pre-check=0",
                    Pragma: "no-cache",
                    Expires: "0",
                }),
            })
            .pipe(
                catchError((error) => {
                    return throwError(error);
                }),
            );
    }

    /**
     *
     *
     * @param {string} url
     * @returns
     * @memberof FileService
     */
    // readFile_v3(url: string) {
    //   return new Promise<string>((resolve, reject) => {
    //     this.httpClient.get<string>(`${url}`).toPromise()
    //       .then(response => {
    //         this.data = response;
    //         resolve(response);
    //       }).catch(err => {
    //         console.error(err);
    //         resolve();
    //       });

    //   });
    // }
}
