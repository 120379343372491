import * as fromRoot from "core-support/store";
import { ReducerService } from "core-support/store";

import { InjectionToken, inject } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";
import {
    CheckConnectionsService,
    ParseUrlService,
} from "core-support/services";
import { UtilsService } from "core-support/utility";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export const REDUCER_TOKEN = new InjectionToken<
    ActionReducerMap<fromRoot.State<any>>
>("Registered Reducers", {
    factory: () => {
        const serv = inject(ReducerService);
        return serv.createReducer();
    },
});

export function initUUidService(urlService: ParseUrlService) {
    return () => {
        urlService.parseUrl();
    };
}

export function CheckConnectionsServiceFactory(utilsService: UtilsService) {
    {
        return () => new CheckConnectionsService(utilsService);
    }
}
