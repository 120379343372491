import { Injectable, InjectionToken, Injector, inject } from "@angular/core";
import { ParseUrlService } from "../parse-url.service";
import { RadarDPCLayer } from "core-support/store";
import { RadarHttpService } from "core-support/rest";
import { TimeDimensionExtensionService } from "../../timedimension/time-dimension-extension.service";
import { ENVIRONMENT } from "core-support/utility";
import { Environment } from "core-support/model";
import { ActionService } from "../../action.service";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
export interface ITypeStrategy {
    /**
     *
     * @param product
     */
    execute(product: RadarDPCLayer): void;
}

class TypeWithHashtag implements ITypeStrategy {
    constructor(
        private productService: RadarHttpService,
        private timeDimensionService: TimeDimensionExtensionService,
        private env: Environment,
        private actionService: ActionService,
    ) {}

    /**
     *
     * @param product
     */
    execute(product: RadarDPCLayer): void {
        this.productService
            .findLastProductByType(product.key)
            .subscribe((res) => {
                this.timeDimensionService.wsProduct = res.lastProducts[0];
                this.timeDimensionService.switchToLoopState(
                    new Date(
                        res.lastProducts[0].time -
                            this.env.centro_meteo_dpc_range,
                    ),
                    new Date(res.lastProducts[0].time),
                    true,
                );
                this.actionService.pruductSelectedSubject$.next(product.key);
            });
    }
}

class TypeWithNoHashtag implements ITypeStrategy {
    constructor(
        private productService: RadarHttpService,
        private timeDimensionService: TimeDimensionExtensionService,
        private actionService: ActionService,
    ) {}

    /**
     *
     * @param product
     */
    execute(product: RadarDPCLayer) {
        this.productService
            .findLastProductByType(product.key)
            .subscribe((res) => {
                this.timeDimensionService.wsProduct = res.lastProducts[0];
                this.timeDimensionService.switchToDefaultState();
                this.actionService.pruductSelectedSubject$.next(product.key);
            });
    }
}

const HASHTAG_STRATEGY = new InjectionToken<ITypeStrategy>("hashtag_strategy", {
    providedIn: "root",
    factory: () =>
        new TypeWithHashtag(
            inject(RadarHttpService),
            inject(TimeDimensionExtensionService),
            inject(ENVIRONMENT),
            inject(ActionService),
        ),
});
const NO_HASHTAG_STRATEGY = new InjectionToken<ITypeStrategy>(
    "no_hashtag_strategy",
    {
        providedIn: "root",
        factory: () =>
            new TypeWithNoHashtag(
                inject(RadarHttpService),
                inject(TimeDimensionExtensionService),
                inject(ActionService),
            ),
    },
);

export interface IUrlStrategy {}

@Injectable({
    providedIn: "root",
})
export class UrlStrategy implements IUrlStrategy {
    isHashtag: boolean;

    constructor(urlService: ParseUrlService, private injector: Injector) {
        urlService.subjectNotifyStrategy$?.subscribe((res: boolean) => {
            this.isHashtag = res;
        });
    }

    /**
     *
     * @param product
     */
    applyOperation(product: RadarDPCLayer): void {
        setTimeout(() => {
            this.injector
                .get(this.isHashtag ? HASHTAG_STRATEGY : NO_HASHTAG_STRATEGY)
                .execute(product);
        });
    }
}
