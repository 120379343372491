import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { GeocodingListComponent } from "../../modal/geocoding/geocoding-list/geocoding-list.component";
import { GeocodingService, MapService } from "core-support/services";
import { Location } from "core-support/model";
import { MatDialog } from "@angular/material/dialog";
import { NgxLeafletPingLayerPluginService } from "@geosdi/ngx-leaflet-ping-layer-plugin";
import { Subscription } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-map-navigator",
    templateUrl: "./navigator.component.html",
    styleUrls: ["./navigator.component.scss"],
    standalone: true,
    imports: [FormsModule, MatButtonModule, TranslateModule],
})
export class NavigatorComponent implements OnInit, OnDestroy {
    @Input()
    styleButton = "button-style-navigator";
    private subscriptions: Subscription = new Subscription();

    constructor(
        private mapService: MapService,
        public geocoder: GeocodingService,
        public dialog: MatDialog,
        private lLeafletPingLayerPluginService: NgxLeafletPingLayerPluginService,
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.geocoder.closeResultSubject$.subscribe(
                (location: Location) => {
                    this.mapService.setView(location.latlng);
                    this.geocoder.address = location.address;
                    this.dialog.closeAll();
                },
            ),
        );
        this.subscriptions.add(
            this.geocoder.openResultSubject$.subscribe(() => {
                this.showGeocodingModal();
            }),
        );

        document
            .getElementById("addressInput")
            .addEventListener("search", (event) => {
                this.reset();
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     *
     *
     * @param {*} event
     * @memberof NavigatorComponent
     */
    onKeyCanc(event: any) {
        if (
            event.key !== "Enter" &&
            (this.geocoder.address === "" || this.geocoder.address === null)
        ) {
            this.reset();
        }
    }

    /**
     *
     */
    reset(): void {
        this.geocoder.address = "";
        this.lLeafletPingLayerPluginService.stopPingLayer$.next(null);
        this.mapService.centerMap();
    }

    /**
     *
     */
    async searchAddress() {
        if (this.geocoder.address) {
            this.geocoder.geocode(this.geocoder.address);
        } else {
            this.mapService.centerMap();
        }
    }

    /**
     *
     */
    async showGeocodingModal() {
        await import("../../modal/geocoding/geocoding-modal.component").then(
            (m) => this.dialog.open(GeocodingListComponent),
        );
    }
}
