import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
    ParseUrlService,
    TimeDimensionExtensionService,
} from "core-support/services";
// import {ProductLayerFilters} from '../../../../store/layer/layer-list.action';
import {
    AppState,
    RadarDPCLayer,
    selectProductLayer,
} from "core-support/store";
import { Store } from "@ngrx/store";
import { UUID } from "angular2-uuid";
import { WideSearchProductRequest } from "core-support/request";
import { take } from "rxjs/operators";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { AsyncPipe, DatePipe, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { RadarHttpService } from "core-support/rest";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Component({
    selector: "radar-download-product-modal",
    templateUrl: "./download-product-modal.component.html",
    styleUrls: ["./download-product-modal.component.scss"],
    standalone: true,
    imports: [
        MatCardModule,
        MatRadioModule,
        FormsModule,
        NgFor,
        MatButtonModule,
        TranslateModule,
        AsyncPipe,
        DatePipe,
    ],
})
export class DownloadProductModalComponent implements OnInit, OnDestroy {
    products: Observable<RadarDPCLayer[]>;
    selectedProduct: RadarDPCLayer = null;
    currentTime: any;
    wideSearchProductRequest: WideSearchProductRequest;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private store: Store<AppState>,
        private timeDimensionService: TimeDimensionExtensionService,
        private productService: RadarHttpService,
        private urlService: ParseUrlService,
    ) {}

    ngOnInit() {
        this.products = this.store.select(selectProductLayer).pipe(take(1));
        this.currentTime = this.timeDimensionService.getCurrentTime();
    }

    /**
     *
     */
    downloadProduct() {
        const minutes: number = new Date(this.currentTime).getMinutes();
        if (
            (this.selectedProduct.key === "SRT1" ||
                this.selectedProduct.key === "SRT3" ||
                this.selectedProduct.key === "SRT6" ||
                this.selectedProduct.key === "SRT12" ||
                this.selectedProduct.key === "SRT24" ||
                this.selectedProduct.key === "TEMP") &&
            minutes > 0
        ) {
            this.currentTime =
                this.timeDimensionService.getCurrentTime() - minutes * 60000;
        }
        this.wideSearchProductRequest = new WideSearchProductRequest(
            this.selectedProduct.key,
            this.currentTime,
        );
        this.subscriptions.add(
            this.productService
                .downloadProduct(this.wideSearchProductRequest)
                .subscribe(),
        );
        this.urlService.updateParam("update", "download-" + UUID.UUID());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
