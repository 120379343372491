import { HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT, UtilsService } from "core-support/utility";
import { WideSearchProductRequest } from "core-support/request";
//@ts-ignore
import saveAs from "file-saver";
import { NgxBasicHttpService } from "@geosdi/ngx-httpservice-utility";
import { Environment } from "core-support/model";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class RadarHttpService extends NgxBasicHttpService {
    constructor(
        private utilsService: UtilsService,
        @Inject(ENVIRONMENT) private env: Environment,
    ) {
        super();
    }

    /**
     *
     * @param type
     */
    findLastProductByType(type: string): Observable<any> {
        const params = type
            ? {
                  params: new HttpParams()
                      .set("type", type)
                      .set("lang", this.utilsService.getCurrentLang()),
              }
            : {};
        return this.httpClient
            .get(`${this.env.restUrl}/product/findLastProductByType`, params)
            .pipe(
                catchError((error) => {
                    this.utilsService.showNotification(
                        "error",
                        "rest_service_error",
                        {
                            serviceName: "ProductService",
                            operation: "operation",
                            message: this.onError(error),
                        },
                    );
                    return throwError(error);
                }),
            );
    }

    /**
     *
     * @param type
     * @param time
     */

    /*    productExistForTime(type: string, time: number): Observable<any> {
const params = type
? {
params: new HttpParams()
.set("type", type)
.set("time", time.toString())
.set("lang", this.utilsService.getCurrentLang()),
}
: {};
return this.httpClient
.get(`${environment.restUrl}/product/existsProduct`, params)
.pipe(
catchError((error) => {
this.handleError<ProductDetailStore>(
"productExistForTime",
null,
);
return throwError(error);
}),
);
}*/

    /**
     *
     * @param wideSearchProductRequest
     */
    downloadProduct(
        wideSearchProductRequest: WideSearchProductRequest,
    ): Observable<any> {
        return this.httpClient
            .post<any>(
                `${this.env.restUrl}/product/downloadProduct`,
                wideSearchProductRequest,
                {
                    responseType: "blob" as "json",
                    observe: "response",
                },
            )
            .pipe(
                map((response: HttpResponse<any>) => {
                    saveAs(
                        response.body,
                        response.headers
                            .get("Content-Disposition")
                            .split(";")[1]
                            .split("=")[1]
                            .replace(/"/g, ""),
                    );
                }),
            )
            .pipe(
                catchError((error) => {
                    this.utilsService.showNotification(
                        "error",
                        "REST_SERVICE_ERROR",
                        {
                            serviceName: "ProductService",
                            operation: "downloadProduct",
                            message: this.onError(error),
                        },
                    );
                    return throwError(error);
                }),
            );
    }

    /**
     *
     * @param url
     * @param date
     * @param subject
     */
    getLayerGeoJson(url: string, date: string, subject: Subject<any>) {
        this.httpClient
            .get(url, {
                params: new HttpParams().set("cql_filter", "time=" + date),
            })
            .subscribe((res) => {
                subject.next(res);
            });
        // .catch(
        //   this.handleError<ProductDetailStore>('getLayerGeoJson', null));
    }
}
