//@ts-ignore
import * as $jquery from "jquery";
//@ts-ignore
import * as L from "leaflet";
//@ts-ignore
import {
    HASHTAG_CENTRO_METEO_DPC,
    MAP_CENTER_CONFIG,
} from "core-support/utility";
import { Inject, Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DeviceDetectorService } from "ngx-device-detector";
import { LayerService } from "./layer/layer.service";
import { MapCenterConfig } from "core-support/model";
import { MediatorService } from "./mediator/mediator.service";
import { NgxLeafletLayersPluginService } from "@geosdi/ngx-leaflet-layers-plugin";
import { NgxLeafletPingLayerPluginService } from "@geosdi/ngx-leaflet-ping-layer-plugin";
import { ParseUrlService } from "../url/parse-url.service";
import { AppState, RadarDPCLayer, selectByLabel } from "core-support/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { TimeDimensionExtensionService } from "../timedimension/time-dimension-extension.service";
import "node_modules/@asymmetrik/leaflet-d3/dist/leaflet-d3.js";
import { HttpClient } from "@angular/common/http";
import * as t from "topojson-client";

/**
 * @author Vito Salvia - CNR IMAA geoSDI Group
 * @email vito.salvia@gmail.com
 */
@Injectable({
    providedIn: "root",
})
export class MapService {
    map: L.Map;
    mapOptions: any;
    closingLayerMenuSubject = new Subject();
    zoom: number;
    subjectNotifyTimeDImension$ = new Subject();
    private currentLayersLoaded = new Map<string, RadarDPCLayer>();
    private url;
    private regionLayer: any;
    private regionGeoJson: any;

    constructor(
        @Inject(MAP_CENTER_CONFIG) private config: MapCenterConfig,
        public breakpointObserver: BreakpointObserver,
        private layerService: LayerService,
        private mediatorService: MediatorService,
        private lLeafletPingLayerPluginService: NgxLeafletPingLayerPluginService,
        private parseUrlService: ParseUrlService,
        @Inject(HASHTAG_CENTRO_METEO_DPC) private hashtagCentroMeteoDPC: string,
        private deviceService: DeviceDetectorService,
        private ngxLeafletLayersPluginService: NgxLeafletLayersPluginService,
        private timeDimensionExtensionService: TimeDimensionExtensionService,
        private store: Store<AppState>,
        private httpClient: HttpClient,
    ) {
        this.zoom = this.parseUrlService.zoom;
        this.url = new URL(window.location.href);
        this.mapOptions = {
            zoom: this.zoom,
            fullscreenControl: true,
            minZoom: 5,
            maxZoom: 11,
            group: true,
            timeZones: ["Local"],
            center: [this.config.lat, this.config.lng],
        };
    }

    /**
     *
     */
    centerMap() {
        this.map.setView(
            new L.LatLng(this.config.lat, this.config.lng),
            this.deviceService.isMobile() ? 5 : 6,
        );
    }

    /**
     *
     * @param latLng
     */
    setView(latLng: L.LatLng) {
        this.map.setView(latLng, 12);
    }

    /**
     *
     * @param map
     */
    bindMap(map: L.Map) {
        this.map = map;
        this.map.scrollWheelZoom.disable();
        this.buildTimeDimension();
        this.buildPingLayer();
        this.addListener();
        this.layerService.buildBaseLayers(this.map);
        this.map.attributionControl.setPrefix(
            "Opera distribuita con Licenza " +
                '<a target="_blank" rel="noopener" href="https://creativecommons.org/licenses/by-sa/4.0/deed.it" title="CC-BY-SA">CC-BY-SA</a> ' +
                'Sviluppato da <a target="_blank" rel="noopener" href="https://www.imaa.cnr.it/" title="CNR IMAA">CNR IMAA</a>',
        );
        this.initSubjectAddLayerToMap();
        this.addRegionsGeojson();
    }

    /**
     *
     */
    getMap(): L.Map {
        return this.map;
    }

    /**
     *
     */
    getMapOptions(): any {
        return this.mapOptions;
    }

    /**
     *
     */
    invalidateSize(): void {
        this.map.invalidateSize();
    }

    /**
     *
     * @param res
     */
    private removeLayerFromMap(res: any) {
        Array.from(this.currentLayersLoaded.values()).forEach((l) => {
            if (!res.layersForProduct.includes(l)) {
                this.map.removeLayer(l.layer);
            }
        });
    }

    /**
     *
     */
    private initSubjectAddLayerToMap() {
        this.layerService.subjectAddLayerProductToMap$.subscribe((res: any) => {
            this.removeLayerFromMap(res);
            res.selectedLayers.forEach((l: any) => {
                this.currentLayersLoaded.set(l.label, l);
                this.map.addLayer(l.layer);
            });
        });
    }

    /**
     *
     */
    private buildTimeDimension() {
        this.timeDimensionExtensionService
            .withMap(this.map)
            .withPlayerOptions({
                transitionTime: this.url.href.includes(
                    this.hashtagCentroMeteoDPC,
                )
                    ? 333
                    : 1000,
                loop: this.url.href.includes(this.hashtagCentroMeteoDPC),
            })
            .applyTimeDimensionPlugin();
        if (this.breakpointObserver.isMatched(["(min-width: 576px)"])) {
            $jquery(".leaflet-bar-timecontrol").appendTo(
                "#timedimensioncontainer",
            );
        }
        this.subjectNotifyTimeDImension$.next(null);
        //$jquery('.leaflet-control-attribution').appendTo('#attributions');
    }

    /**
     *
     */
    private buildPingLayer(): void {
        this.lLeafletPingLayerPluginService
            .withMap(this.map)
            .withStyle("ping-red")
            .withOpacityScale([1, 0])
            .withRadiusScale([2, 18])
            .applyPingLayer();
    }

    private addRegionsGeojson() {
        this.httpClient
            .get(
                "https://raw.githubusercontent.com/openpolis/geojson-italy/master/topojson/limits_IT_regions.topo.json",
            )
            .subscribe((res: any) => {
                this.regionGeoJson = res;
                this.createRegionLayer("#000");
            });
    }

    /**
     *
     */
    private addListener() {
        this.map.on("click", () => {
            this.closingLayerMenuSubject.next(null);
            this.map.scrollWheelZoom.enable();
        });
        this.map.on("overlayadd", (eo: any) => {
            this.mediatorService.subscribeColleague(
                new Date(
                    this.timeDimensionExtensionService.getCurrentTime(),
                ).toISOString(),
                eo,
            );
            this.store
                .select(selectByLabel(eo.name))
                .subscribe((res: RadarDPCLayer[]) => {
                    res.forEach((l) => {
                        eo.layer.setZIndex(res[0].zIndex);
                        this.currentLayersLoaded.set(eo.name, l);
                    });
                });
        });
        this.map.on("overlayremove", (eo: any) => {
            this.mediatorService.unSubcribeColleague(eo.name);
            this.currentLayersLoaded.delete(eo.name);
        });
        this.map.on("baselayerchange", (eo: any) => {
            this.createRegionLayer(eo.name === "DarkBaseMap" ? "#fff" : "#000");
            this.ngxLeafletLayersPluginService.changeBaseLayer(
                eo.layer.options.id,
                false,
            );
        });
        this.map.on("moveend", (eo: any) => {
            this.parseUrlService.updateParam("zoom", this.map.getZoom());
        });
    }

    /**
     *
     * @param color
     * @private
     */
    private createRegionLayer(color: string) {
        if (this.regionLayer) {
            this.map.removeLayer(this.regionLayer);
        }
        this.regionLayer = L.geoJSON(
            t.feature(
                this.regionGeoJson,
                this.regionGeoJson.objects["regions"],
            ),
            {
                style: {
                    color: color,
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0,
                },
            },
        );
        this.regionLayer.addTo(this.map);
    }
}
